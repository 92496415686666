import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as actions from '../actions';
import { connect } from 'react-redux';
import { Alert } from '@mui/material';

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://fanfare.toflawin.fr">
          Batterie Fanfare Chatelaillon Plage
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}

const theme = createTheme();

class Signup extends React.Component {
    state = {
        pseudo: '',
        email: '',
        hasNotif: false,
        password: '',
        confirmPassword: '',
        instrus: '',
        isWaiting: false,
        isLoggin: localStorage.getItem('isLoggin') === undefined ? false : localStorage.getItem('isLoggin'),
        error: ''
    }

    signupUser = () => {
        this.props.signupUser({
            email: this.state.email,
            pseudo: this.state.pseudo,
            password: this.state.password,
            instrus: this.state.instrus,
            notifs: this.state.hasNotif,
            confirmMdp: this.state.confirmPassword,
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({isWaiting: true});
        this.signupUser();
    };

    render() {
        return <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Inscription
            </Typography>
            <Box component="form" noValidate onSubmit={this.handleSubmit} sx={{ mt: 3 }}>
                {(this.props.error != '' && this.props.error !== undefined) && <Alert severity="error">{this.props.error}</Alert>}
                {this.props.isLoggin && <Alert severity="success">{'Votre compte a bien été créé !'}</Alert>}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="pseudo"
                    required
                    fullWidth
                    id="pseudo"
                    label="Pseudo"
                    autoFocus
                    value={this.state.pseudo}
                    onChange={el => this.setState({ pseudo: el.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Adresse Email"
                    name="email"
                    value={this.state.email}
                    onChange={el => this.setState({ email: el.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Mot de passe"
                    type="password"
                    id="password"
                    value={this.state.password}
                    onChange={el => this.setState({ password: el.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirmation mot de passe"
                    type="password"
                    id="confirmPassword"
                    value={this.state.confirmPassword}
                    onChange={el => this.setState({ confirmPassword: el.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="instrus"
                    label="Instruments"
                    type="text"
                    id="instrus"
                    value={this.state.instrus}
                    onChange={el => this.setState({ instrus: el.target.value })}
                    helperText="Séparez chaque instruments par une virgule"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox
                        name="notif"
                        value={this.state.hasNotif}
                        onChange={() => this.setState({ hasNotif: !this.state.hasNotif })}
                        color="primary"
                    />}
                    label="Je souhaite être notifié à chaque évènement auquel je suis inscrit(e)."
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={this.props.isWaiting || (this.state.isWaiting && this.props.error == '') }
              >
                S'inscrire
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="/login" variant="body2">
                    Déjà un compte ? Connectez-vous
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </ThemeProvider>
    }
}

const mapStateToProps = state => {
    return {
        pseudo: state.authentication.pseudo,
        email: state.authentication.email,
        hasNotif: state.authentication.hasNotif,
        password: state.authentication.password,
        confirmPassword: state.authentication.confirmPassword,
        instrus: state.authentication.instrus,
        isWaiting: state.authentication.isWaiting,
        isLoggin: state.authentication.isLoggin,
        error: state.authentication.error
    }
}

export default connect(mapStateToProps, actions)(Signup);