import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from '../pages/Login';
import NotFound from '../pages/NotFound';
import Home from './home';
import requireAuth from '../helpers/require-authentification';
import Signup from '../pages/Signup';

export default class App extends Component {
    render() {
        return <div>
            <Switch>
                <Route exact path="/" component={requireAuth(Home)} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/signup" component={Signup} />
                <Route component={NotFound} />
            </Switch>
        </div>;
    }
}
