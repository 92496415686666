import axios from 'axios';
import { getDomain } from '../helpers';
import { SET_AUTHENTICATION, SET_INSCRIPTION, SET_WAITING } from './action-types';

export function setAuthentification(email, password) {
    return function (dispatch) {
        dispatch({
            type: SET_AUTHENTICATION,
            payload: {email, password}
        })
    }
}

const config = {
    headers: {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Credentials':'true',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers':'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
    }
}

export function signinUser({ email, password}, history) {
    return function (dispatch) {
        let isLoggin = false;
        let isWaiting = true;
        let theError = '';

        axios.post(`${getDomain()}api/login`, {
            username: email,
            password: password
        }).then((response) => {
            // Enregistrement des données
            if (response !== undefined) {
                isLoggin = true;
                localStorage.setItem('userId', response.data.user);
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('pseudo', response.data.pseudo);
                localStorage.setItem('isLoggin', true);
            } else {
                localStorage.setItem('isLoggin', false);
                theError = 'Votre identifiant ou votre mot de passe semble incorrect.';
                isWaiting = false;
            }
            dispatch({ type: SET_AUTHENTICATION, payload: {
                email: email,
                password: password,
                isWaiting: isWaiting,
                isLoggin: isLoggin,
                error: theError
            } })
        }).catch((error) => {
            if (!error.response) {
                console.log('Error: Network error')
            } else {
                console.log(error);
            }
            theError = 'Votre identifiant ou votre mot de passe semble incorrect.'
            isWaiting = false;
            dispatch({
                type: SET_AUTHENTICATION, 
                payload:{
                    email: email,
                    password: password,
                    isWaiting: isWaiting,
                    isLoggin: isLoggin,
                    error: theError
                }
            })
        })
    }
}

export function signupUser({ email, pseudo, password, instrus, notifs, confirmMdp }, history) {
    return function (dispatch) {
        let isWaiting = false;
        let theError = '';
        let isLoggin = false;

        console.log("===");
        console.log(email);
        console.log(password);
        console.log(confirmMdp);
        console.log(pseudo);
        console.log(instrus);
        console.log(notifs);
        console.log("===");
        
        axios.post(`${getDomain()}user/add`, {
            email: email,
            password: password,
            confirmPassword: confirmMdp,
            pseudo: pseudo,
            instrus: instrus,
            notifs: notifs
        }, config).then(response => {
            if (response.data.status === 200) {
                localStorage.setItem('token', response.data.user.token);
                localStorage.setItem('userId', response.data.user.id);
                localStorage.setItem('pseudo', response.data.user.pseudo);
                localStorage.setItem('isLoggin', true);
                isLoggin = true;
            } else {
                theError = response.data.message;
                isWaiting = true;
            }
            dispatch({ type: SET_INSCRIPTION, payload: {
                email: email,
                password: password,
                confirmPassword: confirmMdp,
                pseudo: pseudo,
                instrus: instrus,
                notifs: notifs,
                isLoggin: isLoggin,
                isWaiting: isWaiting,
                error: theError
            } })
        }).catch(error => {
            theError = 'Une erreur est survenue. Veuillez réessayer.';
            isWaiting = true;

            dispatch({ type: SET_INSCRIPTION, payload: {
                email: email,
                password: password,
                confirmPassword: confirmMdp,
                pseudo: pseudo,
                instrus: instrus,
                notifs: notifs,
                isLoggin: isLoggin,
                isWaiting: isWaiting,
                error: theError
            } })
        })
    }
}