import { SET_AUTHENTICATION } from '../actions/action-types';

const initialState = {
    email: '',
    password: '',
    isWaiting: false,
    isLoggin: false,
    error: ''
};

export default function AuthentificationReducer (state = initialState, action) {
    switch (action.type) {
        case SET_AUTHENTICATION:
            return {
                email: action.payload.email,
                password: action.payload.password,
                isWaiting: action.payload.isWaiting,
                isLoggin: action.payload.isLoggin,
                error: action.payload.error,
            }
        default:
            return state;
    }
}