import React, { Component } from 'react';

class Home extends Component {
    render() {
        return <div>

        </div>
    }
}

export default Home;