import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

export default function (Component) {
    class RequireAuthentification extends Component {
        //WARNING! To be deprecated in React v17. Use componentDidMount instead.
        componentWillMount() {
            console.log('test');
            console.log(localStorage.getItem('token'))
            if (localStorage.getItem('token') == undefined || localStorage.getItem('token') == '' || localStorage.getItem('token') == null) {
                this.props.history.push("/login");
            }
        }

        //WARNING! To be deprecated in React v17. Use componentDidUpdate instead.
        componentWillUpdate(nextProps, nextState) {
            if (!nextProps.isLoggin) {
                this.props.history.push('/');
            }
        }

        render() {
            return <Component {...this.props} />
        }
    }

    const mapStateToProps = state => ({
        isLoggin: state.authentication.isLoggin
    })

    return withRouter(connect(mapStateToProps)(RequireAuthentification));
}