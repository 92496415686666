import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from 'react-redux';
import { setAuthentification } from '../actions/index';
import { Alert } from '@mui/material';
import * as actions from '../actions/index';

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://fanfare.toflawin.fr">
          Batterie Fanfare Chatelaillon Plage
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}

const theme = createTheme();

class Login extends React.Component {
    state = {
        email: '',
        password: '',
        isWaiting: false,
        isLoggin: localStorage.getItem('isLoggin') === undefined ? false : localStorage.getItem('isLoggin'),
        error: ''
    };

    loginUser = () => {
        this.props.signinUser({email: this.state.email, password: this.state.password});
    }
    
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({isWaiting: true});
        this.loginUser();
    };
    render() {
        return (
            <ThemeProvider theme={theme}>
              <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                  sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Connexion
                  </Typography>
                  {(this.props.error != '' && this.props.error !== undefined) && <Alert severity="error">{this.props.error}</Alert>}
                  {this.props.isLoggin && <Alert severity="success">{'Vous êtes connecté !'}</Alert>}
                  {
                    localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== '' && localStorage.getItem('token') !== null ?
                        <Box sx={{ mt: 1 }}>
                            <h2>Vous êtes déjà connecté. <a href="">Déconnectez-vous</a> ou <Link href="/">Retournez à l'accueil</Link></h2>
                        </Box> :
                        <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Adresse email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={(el) => this.setState({ email: el.target.value })}
                                defaultValue={this.state.email}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Mot de passe"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={(el) => this.setState({ password: el.target.value })}
                                defaultValue={this.state.password}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={this.props.isWaiting || (this.state.isWaiting && this.props.error == '') }
                            >
                                Se connecter
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2">
                                        Mot de passe oublié ?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="/signup" variant="body2">
                                        {"Inscrivez-vous"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                  }
                  
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
              </Container>
            </ThemeProvider>
          );
    }
    
}

const mapStateToProps = state => {
    return {
        email: state.authentication.email,
        password: state.authentication.password,
        isWaiting: state.authentication.isWaiting,
        isLoggin: state.authentication.isLoggin,
        error: state.authentication.error
    }
}

export default connect(mapStateToProps, actions)(Login);